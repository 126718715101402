// http://ionicframework.com/docs/theming/
//@import '~firebaseui/dist/firebaseui.css';
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
@import '../node_modules/flag-icons/css/flag-icons.min.css';
@import "theme/animate.css";



body{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

ngx-guided-tour .tour-step .tour-buttons .next-button {
  padding: 5px;
}
.alert-message.sc-ion-alert-md{
  font-size:14px;
}
.d-flex{
  display: flex;
}
.dishes-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 14px;
  background-color: #FFFFFF;
  border-radius: 20px!important;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}


app-order{
  width:100%;
}

ion-option{
  font-size: 20px!important;
}

ion-item {
    --ion-item-background: transparent;
}

.btn--tiny {
    padding: 3px;
    width: 22px;
    height: 22px;
    min-width: 0;
    border-radius: 3px;
}

.btn {
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #ddd;
    padding: 4px 8px;
    margin: 5px auto;
    border-radius: 4px;
    color: #fff;
    fill: #fff;
    background: #000;
    line-height: 1em;
    min-width: 190px;
    height: 45px;
    transition: 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-weight: 500;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern';
}
// backgrounds //
.bg-white {
    background-color: #fff;
  }

  .bg-light {
    background-color: var(--ion-color-light);
  }

  .bg-dark {
      background-color: var(--ion-color-dark);
  }

  .bg-primary {
      background-color: var(--ion-color-primary);
  }

  .bg-secondary {
      background-color: var(--ion-color-secondary);
  }

  .bg-tertiary {
      background-color: var(--ion-color-tertiary);
  }

  .bg-profile {
    background-image: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary))
  }

  .bg-profile-invert {
    background-image: linear-gradient(-135deg, var(--ion-color-primary), var(--ion-color-dark))!important
  }

  .bg-main {
      background-image: linear-gradient(-135deg, var(--ion-color-medium), var(--ion-color-light))
  }

  // Helpers //
  .w100 {
    width: 100%;
  }

  .bold,
  .fw500 {
      font-weight: 500 !important;
  }

  .fw700 {
      font-weight: 700 !important;
  }

  .text08 {
    font-size: .8rem;
  }

  .text1 {
    font-size: 1rem !important;
  }

  .text12 {
    font-size: 1.2rem;
  }

  .text15 {
    font-size: 1.5rem;
  }

  .text20 {
    font-size: 2rem;
  }

  .text25 {
    font-size: 2.5rem;
  }

  .text-white {
      color: #fff;
  }

  .text-primary {
      color: var(--ion-color-primary);
  }

  .text-secondary {
    color: var(--ion-color-secondary);
}

  .text-dark {
    color: var(--ion-color-dark);
  }

  // menu //
  // Menu user profile
  ion-toolbar.user-profile {

    .user-avatar {
      width: 68px;
      height: 68px;
    }
    .item-inner {
      border-width: 0;
    }
  }

  // Custom foodIonic toolbar (overriding Shadow DOM)
  ion-toolbar {
    &.ion-color-primary {
      --ion-color-base: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary)) !important;
    }
  }

  // Android card shadow
  ion-card {
    &.sc-ion-card-md-h {
      box-shadow: 0 2px 4px rgba(var(--ion-color-dark-rgb),.24);
    }
  }

  // style for auth pages
  .auth-page {

    .grid {
      padding: 0;
      .col {
        padding: 0;
      }
    }

    .btn-group {
      .button-ios,
      .button-md {
        border-radius: 0;
        &:first-of-type {
          border-radius: 2px 0 0 2px;
        }
        &:last-of-type {
          border-radius: 0 2px 2px 0;
        }
      }
    }

    .auth-content {
      margin: 0 5%;

      .logo {
        width: 60px;
        height: 60px;
        margin: 20px auto 8px;
        border-radius: 10%;
        background: url("/assets/img/foodIonic-ico.png") no-repeat;
        background-size: 100%;
      }

      .list-form {
        padding: 0;
        margin-bottom: 0;

        ion-item {
          background: none;
          padding: 0;

          &:first-child {
            border-top: none;
          }

          &:after {
            display: none;
          }

          ion-label {
            font-size: 1em;
            color: var(--ion-color-light);
          }

          input {
            color: var(--ion-color-light);
          }
        }
      }
    }

  }

  .mto {
    margin-top: 12px;
    ion-icon {
      margin-bottom: -2px;
    }
  }
